import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { SharedModule } from '../../shared/shared.module';
import { NieuwsbriefComponent } from './nieuwsbrief/nieuwsbrief.component';
import { FooterBottomComponent } from './footer-bottom/footer-bottom.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { LacertaMapperModule } from '@lacerta/util';
import { LacertaWagtailModule } from '@lacerta/wagtail';

@NgModule({
  declarations: [FooterComponent, NieuwsbriefComponent, FooterBottomComponent],
  imports: [SharedModule, InlineSVGModule, LacertaMapperModule, LacertaWagtailModule],
  exports: [FooterComponent],
})
export class FooterModule {}
