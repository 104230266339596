<div
  class="footer-bottom"
  fxLayout="row"
  fxLayout.lt-sm="column"
  fxLayoutAlign="center start"
  fxLayoutAlign.lt-sm="center center"
  fxLayoutGap="10rem"
  fxLayoutGap.lt-sm="2rem"
>
  <div fxLayout="column" fxLayoutGap="0.3rem" fxLayout.lt-sm="row" fxLayoutGap.lt-sm="1rem">
    <div><a routerLink="/contact">Contact</a></div>
    <div><a routerLink="/deadlines">Deadlines</a></div>
    <div><a routerLink="/adverteren">Adverteren</a></div>
    <div><a routerLink="/privacy-statement">Privacy Statement</a></div>
  </div>
  <div fxLayout="column" fxLayoutGap="1.5rem" class="initiatief">
    <div class="uppercase">Een initiatief van</div>
    <a href="http://www.muziekmozaiek.be" target="_blank">
      <img src="../../../../assets/images/muziekmozaiek_grey.svg" alt="MuziekMozaiek" />
    </a>
  </div>
  <div fxLayout="column" fxLayoutGap="1.5rem" class="partners">
    <div class="label uppercase">Partners</div>
    <div fxLayout="row" fxLayoutGap="3rem" *ngIf="partners$ | async as partners">
      <div *ngFor="let partner of partners">
        <a *ngIf="partner.value.url" [href]="partner.value.url" target="_blank">
          <img
            *ngIf="partner.type === 'image'"
            [src]="(partner.value.file | lacertaMapper: asImageBlock | wagtailImageRendition: 'small').src"
            alt="Jazz&Mo’"
          />
          <div
            *ngIf="partner.type === 'svg'"
            class="svg"
            aria-label="My icon"
            [inlineSVG]="(partner.value.file | lacertaMapper: asDocumentBlock).url"
          ></div>
        </a>
        <ng-container *ngIf="!partner.value.url">
          <img
            *ngIf="partner.type === 'image'"
            [src]="(partner.value.file | lacertaMapper: asImageBlock | wagtailImageRendition: 'small').src"
            alt="Jazz&Mo’"
          />
          <div
            *ngIf="partner.type === 'svg'"
            class="svg"
            aria-label="My icon"
            [inlineSVG]="(partner.value.file | lacertaMapper: asDocumentBlock).url"
          ></div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
