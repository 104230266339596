import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'autoplay' })
export class AutoplayPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return value;
    }
    return value
      .replace(/(src="https:\/\/www.youtube.com\/[^"']*)/, '$1&autoplay=1')
      .replace(/(src="https:\/\/player.vimeo.com\/video\/[^"']*)/, '$1&autoplay=1');
  }
}
