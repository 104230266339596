import { Component, OnInit } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { Observable } from 'rxjs';
import { OverviewItem, OverviewPage } from '../../core/jazzmo-wagtail/overview/overview.model';
import { map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute } from '@angular/router';
import { JazzmoWagtailPageType } from '../../core/jazzmo-wagtail/page/page.model';
import { JazzmoOverviewApiService } from '../../core/jazzmo-wagtail/overview/overview-api.service';

const hideTypesForPages = ['jazzmo.VideoPage'];

@UntilDestroy()
@Component({
  selector: 'jazzmo-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent extends LacertaDynamicPageWithMetaComponent<OverviewPage> implements OnInit {
  items?: OverviewItem[];
  totalItems?: number;
  hideTypes$: Observable<boolean> = this.currentDetailPage$.pipe(map((page) => hideTypesForPages.indexOf(page.meta.type) >= 0));
  loading = false;
  filter = JazzmoWagtailPageType.overviewItem;

  constructor(
    lacertaCmsNgrxFacade: LacertaCmsNgrxFacade,
    private overviewService: JazzmoOverviewApiService,
    private route: ActivatedRoute
  ) {
    super(lacertaCmsNgrxFacade);
  }

  ngOnInit() {
    this.filter = this.route.snapshot.queryParams.filter || this.filter;
    if (this.context?.id) {
      this.overviewService
        .getByParentId({ parentId: this.context?.id, offset: 0, limit: undefined, pageType: this.filter })
        .pipe(untilDestroyed(this))
        .subscribe((response) => {
          this.items = response.items;
          this.totalItems = response.meta.total_count;
        });
    }
  }

  loadMore() {
    if (this.hasMore && this.context?.id) {
      const offset = this.items?.length;
      this.loading = true;
      this.overviewService
        .getByParentId({ parentId: this.context.id, offset, limit: 30, pageType: this.filter })
        .pipe(untilDestroyed(this))
        .subscribe((response) => {
          this.loading = false;
          this.items?.push(...response.items);
        });
    }
  }

  get hasMore() {
    return this.totalItems && this.items && this.totalItems > this.items?.length;
  }
}
