import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LacertaFormSubmitStatus } from '@lacerta/form';
import { JazzmoMailchimpApiService } from '../../core/jazzmo-wagtail/mailchimp/mailchimp-api.service';

const footerRenderTimeout = 200;

@Component({
  selector: 'jazzmo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  render = false;
  lacertaFormSubmitStatus$ = new Subject<LacertaFormSubmitStatus>();

  constructor(private jazzmoMailchimpApiService: JazzmoMailchimpApiService) {
    setTimeout(() => {
      this.render = true;
    }, footerRenderTimeout);
  }

  handleSubscribe(emailAddress: string) {
    this.lacertaFormSubmitStatus$.next(LacertaFormSubmitStatus.SUBMITTED_VALID);
    this.jazzmoMailchimpApiService.post(emailAddress).subscribe(
      () => {
        this.lacertaFormSubmitStatus$.next(LacertaFormSubmitStatus.SUBMIT_SUCCESS);
      },
      () => {
        this.lacertaFormSubmitStatus$.next(LacertaFormSubmitStatus.SUBMIT_ERROR);
      }
    );
  }
}
