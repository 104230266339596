import { Component } from '@angular/core';
import { LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { LacertaMetaField, mapImageToMetaValues } from '@lacerta/meta';
import { PlayListPage } from '../../core/jazzmo-wagtail/page/page.model';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'jazzmo-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],
})
export class PlaylistComponent extends LacertaDynamicPageWithMetaComponent<PlayListPage> {
  iconPlay = faPlayCircle;

  mapCurrentDetailPageToMetaValues = (page: PlayListPage) => ({
    ...(!this.context?.meta.search_image && page.cover
      ? mapImageToMetaValues(page.cover.url, page.cover.width, page.cover.height)
      : undefined),
    ...(!this.context?.meta.seo_title && { [LacertaMetaField.title]: page.title }),
  });
}
