<section class="items" *ngIf="items && items.length > 0">
  <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="4em" fxLayoutAlign="flex-start">
    <ng-container *ngFor="let item of items">
      <a
        [fxFlex]="videos ? '0 0 calc(33% - 4em)' : '0 0 calc(25% - 4em)'"
        [fxFlex.lt-md]="videos ? '0 0 calc(50% - 4em)' : '0 0 calc(33% - 4em)'"
        fxFlex.lt-sm="100%"
        class="item"
        routerLink="/{{ item.meta.slug }}"
      >
        <div *ngIf="videos" class="thumbnail wide" [ngStyle]="{ 'background-image': 'url(' + item.overview_thumbnail_16_9?.url + ')' }">
          <div class="dot">
            <jazzmo-arrow [secondary]="true"></jazzmo-arrow>
          </div>
        </div>
        <div
          *ngIf="!videos"
          class="thumbnail square"
          [ngStyle]="{ 'background-image': 'url(' + item.overview_thumbnail_square?.url + ')' }"
        >
          <div class="dot">
            <jazzmo-arrow [secondary]="true"></jazzmo-arrow>
          </div>
        </div>
        <div class="type" *ngIf="!hideType">{{ item.meta.type | typeToName }}</div>
        <div class="text">
          <h1>{{ item.title }}</h1>
          <h2>{{ item.overview_subtitle }}</h2>
          <h3>{{ item.overview_series }}</h3>
        </div>
      </a>
    </ng-container>
  </div>
</section>
