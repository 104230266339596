import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { SharedModule } from '../../shared/shared.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { HeaderItemComponent } from './header-item/header-item.component';
import { HeaderSocialLinkComponent } from './header-social-link/header-social-link.component';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { HeaderSubmenuComponent } from './header-submenu/header-submenu.component';
import { LacertaRouterModule } from '@lacerta/cms';
import { HamburgerModule } from '@lacerta/ui';
import { LacertaMapperModule } from '@lacerta/util';

@NgModule({
  declarations: [HeaderComponent, HeaderItemComponent, HeaderSocialLinkComponent, HeaderLogoComponent, HeaderSubmenuComponent],
  imports: [SharedModule, InlineSVGModule, LacertaRouterModule, HamburgerModule, LacertaMapperModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
