import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JazzmoMailchimpApiService {
  private url = '/api/mailchimp/';

  constructor(private http: HttpClient) {}

  post(mailAddress: string): Observable<Response> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    return this.http.post<Response>(this.url, { email: mailAddress, list: 'jazzmo' });
  }
}
