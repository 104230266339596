import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { JazzmoAppComponent } from './app.component';
import { JazzmoCoreModule } from './core/core.module';
import { HeaderModule } from './feature/header/header.module';
import { FooterModule } from './feature/footer/footer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { registerLocaleData } from '@angular/common';

import localeNl from '@angular/common/locales/nl';
import { MixcloudModule } from './feature/mixcloud/mixcloud.module';
import { GetjazzmoModule } from './feature/getjazzmo/getjazzmo.module';

registerLocaleData(localeNl);

@NgModule({
  imports: [BrowserModule, JazzmoCoreModule, HeaderModule, FooterModule, FlexLayoutModule, MixcloudModule, GetjazzmoModule, HammerModule],
  declarations: [JazzmoAppComponent],
  providers: [{ provide: LOCALE_ID, useValue: 'nl' }],
  bootstrap: [JazzmoAppComponent],
})
export class AppModule {}
