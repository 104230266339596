import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowModule } from './arrow/arrow.module';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LacertaCmsModule } from '@lacerta/cms';
import { AutoplayPipe } from './autoplay.pipe';
import { TagModule } from './tag/tag.module';
import { TypeToNamePipe } from './type-to-name.pipe';

@NgModule({
  imports: [CommonModule, RouterModule, ArrowModule, TagModule, FlexLayoutModule, FontAwesomeModule, LacertaCmsModule],
  declarations: [AutoplayPipe, TypeToNamePipe],
  exports: [
    CommonModule,
    RouterModule,
    ArrowModule,
    TagModule,
    FlexLayoutModule,
    FontAwesomeModule,
    LacertaCmsModule,
    AutoplayPipe,
    TypeToNamePipe,
  ],
})
export class SharedModule {}
