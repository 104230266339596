import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

@Component({
  selector: 'jazzmo-header-social-link',
  template: `
    <a [href]="link" target="_blank">
      <fa-icon *ngIf="icon" [icon]="icon" size="lg"></fa-icon>
    </a>
  `,
})
export class HeaderSocialLinkComponent {
  @Input()
  link?: string;
  @Input()
  icon?: IconDefinition;
}
