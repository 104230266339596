<div class="container">
  <ng-container *ngIf="current?.type === 'image'">
    <div
      class="image columns"
      (swipeleft)="previous()"
      (swiperight)="next()"
      [ngStyle]="{ 'background-image': current ? 'url(' + current.value.image.renditions['large'].src + ')' : undefined }"
    >
      <div class="previous" (click)="previous()" title="previous">&#8701;</div>
      <div class="next" (click)="next()" title="next">&#8702;</div>
    </div>
  </ng-container>
</div>

<img *ngIf="preload" class="preload" [src]="preload.value.image.renditions['large'].src" alt="preload" />
