import { Component, Input } from '@angular/core';
import { HeaderSubMenuItem } from './header-submenu-item.model';

@Component({
  selector: 'jazzmo-header-submenu',
  template: `
    <ng-container *ngIf="items">
      <div
        class="submenu"
        fxLayout="row"
        [fxLayoutAlign]="offset > 0 ? 'start stretch' : 'center stretch'"
        fxLayoutGap="4rem"
        fxLayout.lt-md="column"
        fxLayoutGap.lt-md="0"
        ngClass.gt-sm="background-primary"
        style="border-bottom: 1px solid white"
        [ngStyle]="{ 'padding-left.px': '' + (offset > 0 ? offset : 0) }"
      >
        <jazzmo-header-item *ngFor="let item of items" [link]="item.slug | slugRoute" [queryParams]="{ filter: item.filter }">{{
          item.title
        }}</jazzmo-header-item>
      </div>
    </ng-container>
  `,
})
export class HeaderSubmenuComponent {
  @Input()
  items?: HeaderSubMenuItem[] | null;

  @Input()
  offset = 0;
}
