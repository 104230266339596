import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { JazzmoCustomPath } from '../../core/custom-path.model';
import { faFacebookSquare, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { concat, Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { JazzmoRootPage } from '../../core/jazzmo-wagtail/root/root.model';
import { HeaderSubMenuItem } from './header-submenu/header-submenu-item.model';
import { JazzmoWagtailPageType } from '../../core/jazzmo-wagtail/page/page.model';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';
import { cast, LacertaRoutePaths } from '@lacerta/util';

const subMenuItems: { [key: string]: HeaderSubMenuItem[] } = {
  sound: [
    { slug: 'sound', title: 'Playlist', filter: 'jazzmo.PlayListPage' },
    { slug: 'sound', title: 'Podcast', filter: 'jazzmo.PodcastPage' },
  ],
  review: [
    { slug: 'review', title: 'Albumreview', filter: 'jazzmo.AlbumReviewPage' },
    { slug: 'review', title: 'Concertreview', filter: 'jazzmo.ConcertReviewPage' },
  ],
};

const subMenuItemsMouseLeaveDelay = 1000;
const timeoutMultiplier = 200;
const nrTimeoutIds = 10;

@Component({
  selector: 'jazzmo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy, OnChanges {
  @Input()
  menuPages?: WagtailMenuPage[] | null;
  @Input()
  currentSlug?: string | null;

  @ViewChild('menu', { read: ElementRef, static: true })
  menu?: ElementRef;

  logo$ = this.lacertaCmsNgrxFacade.rootPage$.pipe(
    cast<JazzmoRootPage>(),
    map((rootPage) => rootPage.logo[0])
  );

  jazzmoCustomPath = JazzmoCustomPath;
  lacertaRoutePaths = LacertaRoutePaths;
  icons = {
    facebookSquare: faFacebookSquare,
    youtube: faYoutube,
    instagram: faInstagram,
    search: faSearch,
  };

  subMenuItems$?: Observable<HeaderSubMenuItem[] | null>;

  menuOpen = false;
  submenuOffset = -1;
  defaultSubmenuOffset = -1;
  timeoutIDs: number[] = [];

  constructor(private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade, private cd: ChangeDetectorRef) {}

  get standardPages() {
    return this.menuPages ? this.menuPages.filter((menupage) => menupage.page.meta.type !== JazzmoWagtailPageType.contentPage) : [];
  }

  get contentMenuPages() {
    return this.menuPages ? this.menuPages.filter((menupage) => menupage.page.meta.type === JazzmoWagtailPageType.contentPage) : [];
  }

  get defaultSubmenuItems() {
    return this.currentSlug ? subMenuItems[this.currentSlug] : null;
  }

  handleMouseOver(item: WagtailMenuPage, event: MouseEvent) {
    const target = event.target as HTMLElement;
    const subMenuItem = subMenuItems[item.page.meta.slug];
    if (subMenuItem) {
      this.submenuOffset = target.offsetLeft;
      this.subMenuItems$ = of(subMenuItem);
    }
  }

  handleMouseLeave() {
    this.subMenuItems$ = concat(this.subMenuItems$ ?? [], of(null).pipe(delay(subMenuItemsMouseLeaveDelay)));
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setDefaultSubmenuOffset(true);
    this.setHeaderHeight();
  }

  ngOnChanges(): void {
    this.subMenuItems$ = of(null);
    const newTimeOutIds = [...new Array(nrTimeoutIds).keys()].map((index) =>
      window.setTimeout(() => {
        this.setDefaultSubmenuOffset(true);
        this.setHeaderHeight();
      }, index * timeoutMultiplier)
    );
    this.timeoutIDs.push(...newTimeOutIds);
  }

  setHeaderHeight() {
    document.body.style.setProperty('--header-height', `${this.menu?.nativeElement.getBoundingClientRect().height}px`);
  }

  setDefaultSubmenuOffset(force = false) {
    let offset = 0;
    if (this.menu?.nativeElement.querySelector('.is-active')) {
      offset = this.menu.nativeElement.querySelector('.is-active').offsetLeft;
    }

    if ((force || this.defaultSubmenuOffset <= 0) && this.defaultSubmenuOffset !== offset) {
      this.defaultSubmenuOffset = offset;
      this.cd.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.timeoutIDs.forEach(clearTimeout);
    this.timeoutIDs.length = 0;
  }
}
