import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class MixcloudService {
  private subject$ = new Subject<string>();

  play(url: string) {
    this.subject$.next(url);
  }

  get events$() {
    return this.subject$.asObservable();
  }
}
