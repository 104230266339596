<div class="background"></div>

<ng-container *ngIf="currentDetailPage$ | async as item">
  <section class="item" *ngIf="!playing" [ngStyle]="{ 'background-image': 'url(' + item.background_image.url + ')' }">
    <div class="dot" (click)="play()">
      <jazzmo-arrow [secondary]="true"></jazzmo-arrow>
    </div>
  </section>

  <section class="play" *ngIf="playing">
    <div class="video" *ngIf="getVideo(item.video_or_images) as video" [innerHtml]="video.value.video.html | autoplay | sanitizeHtml"></div>
    <jazzmo-detail-gallery
      *ngIf="!getVideo(item.video_or_images)"
      [images]="item.video_or_images"
      (update)="setBijSchrift($event)"
    ></jazzmo-detail-gallery>
  </section>

  <section class="sidebar" fxLayout="column" [ngClass]="{ closed: closed }">
    <div class="topbar" fxFlex="0 0 auto">
      {{ item.meta.type | typeToName }}
      <span class="dot" (click)="closed = !closed">
        <fa-icon *ngIf="!closed" [icon]="iconClose"></fa-icon>
        <fa-icon *ngIf="closed" [icon]="iconInfo"></fa-icon>
      </span>
    </div>
    <div *ngIf="bijschrift" class="bijschrift">{{ bijschrift }}</div>
    <article fxFlex fxFlex.lt-md="1 1 auto">
      <header>
        <h1>{{ item.title }}</h1>
        <h2>{{ item.subtitle }}</h2>
        <h3>{{ item.date | date: 'EEEE d.M.yyyy' }}</h3>
      </header>
      <ul class="tags">
        <li *ngIf="item.series"><jazzmo-tag [value]="item.series"></jazzmo-tag></li>
        <li *ngFor="let tag of item.tags"><jazzmo-tag [value]="tag"></jazzmo-tag></li>
      </ul>
      <main>
        <lacerta-rich-text-block [content]="item.body"></lacerta-rich-text-block>
      </main>
      <ul class="credits">
        <li *ngFor="let credit of item.credits">
          <label>{{ credit.value.type }}</label>
          {{ credit.value.name }}
        </li>
        <!-- TODO: add search link -->
      </ul>
      <hr />
      <!-- TODO: h3 class="meer">Meer van dit?</h3-->
    </article>
  </section>
</ng-container>
