import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OverviewFields, OverviewItem } from '../../core/jazzmo-wagtail/overview/overview.model';
import { JazzmoWagtailPageType } from '../../core/jazzmo-wagtail/page/page.model';
import { PagesResponse, WagtailPagesApiService } from '@lacerta/wagtail';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@UntilDestroy()
@Component({
  selector: 'jazzmo-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @ViewChild('search_input', { read: ElementRef, static: true }) searchInput?: ElementRef;

  readonly items: OverviewItem[] = [];
  inputTimer?: number;
  initialQuery = '';

  searchIcon = faSearch;

  constructor(private pagesService: WagtailPagesApiService, private route: ActivatedRoute) {}

  keydown(): void {
    clearTimeout(this.inputTimer);
  }

  keyup(): void {
    clearTimeout(this.inputTimer);
    const minChars = 3;
    if (this.searchInput?.nativeElement.value.length >= minChars) {
      const timeout = 500;
      this.inputTimer = window.setTimeout(() => this.search(this.searchInput?.nativeElement.value), timeout);
    }
  }

  ngOnInit(): void {
    const query: string | null = this.route.snapshot.queryParamMap.get('q');
    const tag: string | null = this.route.snapshot.queryParamMap.get('tag');
    if (query) {
      this.initialQuery = query;
      this.search(query);
    } else if (tag) {
      this.initialQuery = tag;
      this.search(tag, true);
    }
    this.searchInput?.nativeElement.focus();
  }

  search(query: string, searchByTag = false): void {
    this.pagesService
      .get<OverviewItem>({
        search: query,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        search_operator: 'and',
        type: JazzmoWagtailPageType.overviewItem,
        fields: [
          OverviewFields.overview_subtitle,
          OverviewFields.overview_thumbnail_square,
          OverviewFields.overview_series,
          OverviewFields.tags,
        ],
      })
      .pipe(untilDestroyed(this))
      .subscribe((pagesResponse: PagesResponse<OverviewItem>) => {
        this.items.length = 0;
        this.items.push(
          ...pagesResponse.items.filter(
            (item) => !searchByTag || item.overview_series === query || (item.tags && item.tags.indexOf(query) >= 0)
          )
        );
      });
  }
}
