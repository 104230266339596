<section class="container" *ngIf="currentDetailPage$ | async as jazzplan">
  <header>
    <h2>Jazzplan {{ jazzplan.identifier }}</h2>
    &nbsp;
    <h1>{{ jazzplan.title }}</h1>
  </header>
  <hr />

  <ul class="plan" *ngIf="jazzplan.plan">
    <li *ngFor="let image of jazzplan.plan">
      <img [src]="image.value.renditions['large'].src" alt="plan" />
    </li>
  </ul>

  <ul class="locations">
    <li *ngFor="let location of jazzplan.locations; let i = index" fxLayout="row wrap" fxLayoutAlign="flex-start" fxLayout.lt-sm="column">
      <div fxFlex="0 1 calc(33.3% - 3em)" class="title">
        <div class="dot">{{ i + 1 }}</div>
        <h4>{{ location.value.name }}</h4>
        {{ location.value.address }}
      </div>
      <div fxFlex="0 1 50%" class="description" [innerHtml]="location.value.description"></div>
    </li>
  </ul>
</section>
