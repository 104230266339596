import { NgModule } from '@angular/core';
import { AlbumReviewComponent } from './album-review.component';
import { SharedModule } from '../../shared/shared.module';
import { OverviewModule } from '../overview/overview.module';

@NgModule({
  declarations: [AlbumReviewComponent],
  imports: [SharedModule, OverviewModule],
})
export class AlbumReviewModule {}
