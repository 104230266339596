import { Component, Input } from '@angular/core';

@Component({
  selector: 'jazzmo-header-item',
  template: `
    <a
      *ngIf="link"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center"
      fxLayout.lt-md="row"
      [routerLink]="link"
      [queryParams]="queryParams || {}"
      routerLinkActive="is-active"
      ngClass.lt-md="menu-item-responsive"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <a
      *ngIf="href"
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center"
      fxLayout.lt-md="row"
      [href]="href"
      target="_blank"
      ngClass.lt-md="menu-item-responsive"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['./header-item.component.scss'],
})
export class HeaderItemComponent {
  @Input()
  link?: string;
  @Input()
  href?: string;
  @Input()
  queryParams?: { [key: string]: unknown };
}
