import { NgModule } from '@angular/core';
import { MixcloudComponent } from './mixcloud.component';
import { MixcloudService } from './mixcloud.service';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [MixcloudComponent],
  imports: [SharedModule],
  providers: [MixcloudService],
  exports: [MixcloudComponent],
})
export class MixcloudModule {}
