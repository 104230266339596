import { LacertaMetaConfig, LacertaMetaField, LacertaMetaFields, mapHtmlOnMetaValue, mapImageToMetaValues } from '@lacerta/meta';
import { AlbumReviewPage, JazzmoWagtailPageType, PodCastPage } from './jazzmo-wagtail/page/page.model';
import { DetailPage } from './jazzmo-wagtail/detail/detail.model';

export const detailPageMetaMapping = (page: DetailPage) => ({
  ...(page.background_image && mapImageToMetaValues(page.background_image.url, page.background_image.width, page.background_image.height)),
  ...(page.body && { [LacertaMetaField.description]: mapHtmlOnMetaValue(page.body) }),
  ...{ [LacertaMetaField.title]: `${page.title} - ${page.subtitle}` },
});

export const albumReviewMetaMapping = (page: AlbumReviewPage): LacertaMetaFields => ({
  ...(page.cover && mapImageToMetaValues(page.cover.url, page.cover.width, page.cover.height)),
  ...(page.review && { [LacertaMetaField.description]: mapHtmlOnMetaValue(page.review) }),
  ...{ [LacertaMetaField.title]: `${page.artist}: ${page.title}` },
});
export const podcastMetaMapping = (page: PodCastPage) => ({
  ...(page.cover && mapImageToMetaValues(page.cover.url, page.cover.width, page.cover.height)),
  ...(page.body && { [LacertaMetaField.description]: mapHtmlOnMetaValue(page.body) }),
  ...{ [LacertaMetaField.title]: `${page.artist}: ${page.title}` },
});

export const metaConfig: LacertaMetaConfig<DetailPage & AlbumReviewPage & PodCastPage> = {
  customPageMetaMappings: [
    [JazzmoWagtailPageType.albumReviewPage, albumReviewMetaMapping],
    [JazzmoWagtailPageType.eventDetailPage, detailPageMetaMapping],
    [JazzmoWagtailPageType.concertReviewPage, detailPageMetaMapping],
    [JazzmoWagtailPageType.videoDetailpage, detailPageMetaMapping],
    [JazzmoWagtailPageType.podcastPage, podcastMetaMapping],
  ],
};
