import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagesResponse, WagtailPagesApiService } from '@lacerta/wagtail';
import { JazzmoWagtailPageType } from '../page/page.model';
import { OverviewFields, OverviewItem } from './overview.model';
import { map } from 'rxjs/operators';

interface GetByParentIdParams {
  parentId: number;
  offset?: number;
  limit?: number;
  pageType?: JazzmoWagtailPageType;
}

const defaultOverviewLimit = 8;

@Injectable()
export class JazzmoOverviewApiService {
  constructor(private pagesService: WagtailPagesApiService) {}

  getByParentId({
    parentId,
    offset,
    limit = defaultOverviewLimit,
    pageType = JazzmoWagtailPageType.overviewItem,
  }: GetByParentIdParams): Observable<PagesResponse<OverviewItem>> {
    return this.pagesService.get<OverviewItem>({
      type: pageType,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      child_of: parentId,
      fields: [
        OverviewFields.overview_subtitle,
        OverviewFields.overview_thumbnail_16_9,
        OverviewFields.overview_thumbnail_square,
        OverviewFields.overview_series,
      ],
      limit,
      offset,
      order: '-path',
    });
  }

  getMoreItems(query: string, self: OverviewItem, limit = 3): Observable<OverviewItem[]> {
    return this.pagesService
      .get<OverviewItem>({
        search: query,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        search_operator: 'and',
        type: JazzmoWagtailPageType.overviewItem,
        fields: [
          OverviewFields.overview_subtitle,
          OverviewFields.overview_thumbnail_16_9,
          OverviewFields.overview_thumbnail_square,
          OverviewFields.overview_series,
        ],
        limit: limit + 1,
      })
      .pipe(
        map((pagesResponse: PagesResponse<OverviewItem>) =>
          pagesResponse.items
            .filter((item) => item.id !== self.id && (query === item.title || query === item.overview_subtitle))
            .slice(0, limit)
        )
      );
  }
}
