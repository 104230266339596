import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OverviewItem } from '../../../core/jazzmo-wagtail/overview/overview.model';

@Component({
  selector: 'jazzmo-overview-items',
  templateUrl: './overview-items.component.html',
  styleUrls: ['./overview-items.component.scss'],
})
export class OverviewItemsComponent implements OnChanges {
  @Input() items?: OverviewItem[];
  @Input() hideType: boolean | null = false;

  videos = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items']) {
      if (this.items) {
        this.videos = this.items.length > 0 && this.items[0].meta.type === 'jazzmo.VideoDetailPage';
      }
    }
  }
}
