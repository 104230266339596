import { Component, HostListener, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { WagtailMenuPage } from '@lacerta/wagtail';
import { ThemeService } from './core/theme.service';
import { DOCUMENT } from '@angular/common';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';

const minScrollY = 100;
const minScrollTop = 50;

@Component({
  selector: 'jazzmo-root',
  template: `
    <jazzmo-header [menuPages]="menuPages$ | async" [currentSlug]="currentSlug$ | async"></jazzmo-header>
    <div class="container">
      <div fxLayout="column" class="content" (scroll)="scrolling($event)">
        <div fxFlex>
          <router-outlet></router-outlet>
        </div>
        <jazzmo-footer fxFlex="0 0 auto"></jazzmo-footer>
      </div>
      <jazzmo-mixcloud></jazzmo-mixcloud>
    </div>
    <jazzmo-get-yours></jazzmo-get-yours>
  `,
  styleUrls: ['./app.component.scss'],
})
export class JazzmoAppComponent {
  constructor(
    private lacertaCmsNgrxFacade: LacertaCmsNgrxFacade,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.themeService.loadTheme();
  }
  menuPages$: Observable<WagtailMenuPage[]> = this.lacertaCmsNgrxFacade.menu$;
  currentSlug$ = this.lacertaCmsNgrxFacade.currentSlug$;

  scrolling(event: Event) {
    const target = event.target as HTMLElement;
    if (target.scrollTop > minScrollTop) {
      this.document.body.classList.add('scrolled');
    } else {
      this.document.body.classList.remove('scrolled');
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.scrollY > minScrollY) {
      this.document.body.classList.add('scrolled');
    } else {
      this.document.body.classList.remove('scrolled');
    }
  }
}
