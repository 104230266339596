import { NgModule } from '@angular/core';
import { TagComponent } from './tag.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [TagComponent],
  imports: [RouterModule],
  exports: [TagComponent],
})
export class TagModule {}
