import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LacertaFormSubmitStatus } from '@lacerta/form';

@Component({
  selector: 'jazzmo-nieuwsbrief',
  template: `
    <div class="nieuwsbrief background-primary" fxLayout="row" fxLayoutAlign="center center">
      <div *ngIf="!subscribed" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="1rem">
        <div class="inschrijven">Schrijf je in voor onze nieuwsbrief</div>
        <div>
          <input type="text" placeholder="emailadres" #emailAddressInput [disabled]="subscriptionDisabled" />
          <button (click)="handleSubscribe(emailAddressInput.value)" [disabled]="subscriptionDisabled">verzenden</button>
        </div>
        <div class="disclaimer">
          Door op verzenden te klikken ga je akkoord met onze <a routerLink="/privacy-statement">algemene voorwaarden</a>
        </div>
      </div>
      <div *ngIf="subscribed">Bedankt voor je inschrijving op onze nieuwsbrief!</div>
    </div>
    <div class="error" *ngIf="submitError">
      Er ging iets mis. Gelieve na te kijken of er een geldig e-mailadres werd ingevoerd en probeer het opnieuw.
    </div>
  `,
  styleUrls: ['./nieuwsbrief.component.scss'],
})
export class NieuwsbriefComponent {
  @Output()
  subscribe = new EventEmitter<string>();

  @Input()
  lacertaFormSubmitStatus?: LacertaFormSubmitStatus | null;

  handleSubscribe(emailAddress: string) {
    this.subscribe.emit(emailAddress);
  }

  get subscriptionDisabled() {
    return (
      this.lacertaFormSubmitStatus &&
      [LacertaFormSubmitStatus.NOT_SUBMITTED, LacertaFormSubmitStatus.SUBMITTED_VALID, LacertaFormSubmitStatus.SUBMIT_SUCCESS].includes(
        this.lacertaFormSubmitStatus
      )
    );
  }

  get submitError() {
    return this.lacertaFormSubmitStatus === LacertaFormSubmitStatus.SUBMIT_ERROR;
  }

  get subscribed() {
    return this.lacertaFormSubmitStatus === LacertaFormSubmitStatus.SUBMIT_SUCCESS;
  }
}
