import { Component } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { HomeModel, SpotLight } from './home.model';

@Component({
  selector: 'jazzmo-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class JazzmoHomeComponent extends LacertaDynamicPageWithMetaComponent<HomeModel> {
  spotLight$: Observable<SpotLight> = this.currentDetailPage$.pipe(
    filter((page) => !!page.spotlight),
    tap((page) => this.lacertaCmsNgrxFacade.loadPageById(page.spotlight.id)),
    switchMap((page) => this.lacertaCmsNgrxFacade.selectDetailPageById(page.spotlight.id)),
    map((page) => page as SpotLight)
  );

  constructor(lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {
    super(lacertaCmsNgrxFacade);
  }
}
