/* eslint-disable @typescript-eslint/naming-convention */
import { LacertaImageChooserBlock, LacertaWagtailPage } from '@lacerta/cms';
import { WagtailImageRenditionField, WagtailStreamField } from '@lacerta/wagtail';
import { OverviewItem } from '../overview/overview.model';

export enum JazzmoWagtailPageType {
  overviewItem = 'jazzmo.OverviewItem',
  homePage = 'jazzmo.HomePage',
  videoPage = 'jazzmo.VideoPage',
  videoDetailpage = 'jazzmo.VideoDetailPage',
  soundPage = 'jazzmo.SoundPage',
  extraPage = 'jazzmo.ExtraPage',
  playlistPage = 'jazzmo.PlayListPage',
  jazzplanPage = 'jazzmo.JazzplanPage',
  podcastPage = 'jazzmo.PodcastPage',
  reviewPage = 'jazzmo.ReviewPage',
  concertReviewPage = 'jazzmo.ConcertReviewPage',
  albumReviewPage = 'jazzmo.AlbumReviewPage',
  eventPage = 'jazzmo.EventPage',
  eventDetailPage = 'jazzmo.EventDetailPage',
  contentPage = 'jazzmo.ContentPage',
  root = 'jazzmo.JazzMoRootPage',
}

export interface AlbumReviewPage extends OverviewItem {
  artist: string;
  series: string;
  label: string;
  year: string;
  tags: string[];
  cover: WagtailImageRenditionField;
  review: string;
}

export interface PodCastPage extends OverviewItem {
  artist: string;
  cover: WagtailImageRenditionField;
  mixcloud_url: string;
  body: string;
  tags: string[];
  series: string;
}

export interface Song {
  type: string;
  value: {
    artist: string;
    title: string;
    description: string;
    link: {
      url: string;
      text: string;
    };
  };
}

export interface PlayListPage extends OverviewItem {
  identifier: string;
  cover: WagtailImageRenditionField;
  spotify_url: string;
  playlist: Song[];
}

export interface PlanLocation {
  type: string;
  value: {
    name: string;
    address: string;
    description: string;
  };
}

export interface JazzplanPage extends OverviewItem {
  identifier: string;
  cover: WagtailImageRenditionField;
  plan: WagtailStreamField<LacertaImageChooserBlock>[];
  locations: PlanLocation[];
}

export interface ContentCoverBlock {
  image: LacertaImageChooserBlock;
  url: string;
}

export interface ContentButtonBlock {
  text: string;
  url: string;
}

export interface ContentBlock {
  title: string;
  text: string;
  links: (WagtailStreamField<ContentCoverBlock> | WagtailStreamField<ContentButtonBlock>)[];
}

export interface ContentPage extends LacertaWagtailPage {
  subtitle: string;
  body: WagtailStreamField<ContentBlock>[];
}
