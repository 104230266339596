import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DetailPresentation } from '../../../core/jazzmo-wagtail/detail/detail.model';

const nextTimeout = 3000;
const previousNextTimeout = 5000;

@Component({
  selector: 'jazzmo-detail-gallery',
  templateUrl: './detail-gallery.component.html',
  styleUrls: ['./detail-gallery.component.scss'],
})
export class DetailGalleryComponent implements OnInit, OnDestroy {
  @Input() images?: DetailPresentation[];
  @Output() update: EventEmitter<DetailPresentation> = new EventEmitter();

  private _images?: DetailPresentation[];
  current?: DetailPresentation | null;
  preload?: DetailPresentation | null;
  private currentIndex?: number;
  private timer?: number;

  ngOnInit(): void {
    this._images = this.images?.filter((image) => image.type === 'image');
    this.next();
  }

  previous(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (this.nrImages === 0) {
      this.current = null;
      this.currentIndex = -1;
    }
    if (!this.current) {
      this.currentIndex = 0;
    } else {
      this.currentIndex = this.currentIndexOrZero - 1;
      if (this.currentIndexOrZero < 0) {
        this.currentIndex = this.nrImages - 1;
      }
    }

    this.current = this._images?.[this.currentIndexOrZero];

    this.timer = window.setTimeout(() => this.next(), previousNextTimeout);
  }

  next(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.currentIndex = this.getNextIndex(this.currentIndexOrZero);

    if (this.currentIndex >= 0) {
      this.current = this._images?.[this.currentIndex];
      this.update.emit(this.current);
    }

    const nextIndex = this.getNextIndex(this.currentIndex);
    if (nextIndex >= 0) {
      this.preload = <DetailPresentation>this._images?.[nextIndex];
    } else {
      this.preload = null;
    }

    this.timer = window.setTimeout(() => this.next(), nextTimeout);
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  private getNextIndex(start: number) {
    let index = start;
    const nrImages = this.nrImages;
    if (nrImages === 0) {
      index = -1;
    }
    if (!this.current) {
      index = 0;
    } else {
      index++;
      if (index >= nrImages) {
        index = 0;
      }
    }
    return index;
  }

  get nrImages() {
    return this._images?.length ?? 0;
  }

  get currentIndexOrZero() {
    return this.currentIndex ?? 0;
  }
}
