import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import type { LacertaUiImage, LacertaUiVideo } from './image.model';

const playVideoTimeout = 100;

@Component({
  selector: 'lacerta-hero',
  template: `
    <div class="hero">
      <div class="video" *ngIf="video">
        <video autoplay loop muted id="bgvid" #bgvid>
          <source [src]="video.mp4_url" *ngIf="video.mp4_url" type="video/mp4" />
          <source [src]="video.webm_url" *ngIf="video.webm_url" type="video/webm" />
          <source [src]="video.ogg_url" *ngIf="video.ogg_url" type="video/ogg" />
        </video>
      </div>
      <div class="background_image" *ngIf="!video && image" [lacertaBackgroundImage]="image"></div>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['hero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroComponent implements OnInit, OnChanges {
  @Input()
  video?: LacertaUiVideo;
  @Input()
  images?: LacertaUiImage[];
  @Input()
  rendition = 'large';

  image?: LacertaUiImage | null;

  @ViewChild('bgvid', { read: ElementRef }) bgvid?: ElementRef;

  ngOnInit(): void {
    setTimeout(() => this.playVideo(), playVideoTimeout);
    if (this.images && this.images.length > 0) {
      this.image = this.images[Math.floor(Math.random() * this.images.length)];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['video']) {
      setTimeout(() => this.playVideo(), playVideoTimeout);
    }
    if (changes['images']) {
      if (this.images && this.images.length > 0) {
        this.image = this.images[Math.floor(Math.random() * this.images.length)];
      } else {
        this.image = null;
      }
    }
  }

  playVideo() {
    if (this.bgvid) {
      this.bgvid.nativeElement.setAttribute('muted', 'true');
      this.bgvid.nativeElement.muted = true;
      // noinspection JSIgnoredPromiseFromCall
      this.bgvid.nativeElement.play();
    }
  }
}
