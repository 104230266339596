<ng-container *ngIf="currentDetailPage$ | async as overview">
  <section class="intro" *ngIf="overview.intro">
    <h2 class="intro" [innerHtml]="overview.intro"></h2>
  </section>
</ng-container>

<jazzmo-overview-items [items]="items" [hideType]="hideTypes$ | async"></jazzmo-overview-items>

<section class="more" *ngIf="hasMore" (click)="loadMore()">
  <div class="text">
    <h1>toon meer</h1>
  </div>
  <jazzmo-arrow [tail]="true" rotate="90deg" [spinning]="loading"></jazzmo-arrow>
</section>
