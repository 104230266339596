<header fxLayout="column" (mouseleave)="handleMouseLeave()">
  <div fxLayout="row" class="main" #menu fxLayoutAlign="space-around stretch" fxLayout.lt-md="column">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <jazzmo-header-logo
        fxFlex
        class="logo"
        [logo]="logo$ | async"
        [link]="lacertaRoutePaths.home | slugRoute"
        alt="Jazz&Mo’"
      ></jazzmo-header-logo>
      <lacerta-hamburger [fxHide.gt-sm]="true" [(menuOpen)]="menuOpen"></lacerta-hamburger>
    </div>
    <div
      [fxShow.lt-md]="menuOpen"
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="4rem"
      fxLayout.lt-md="column"
      fxLayoutGap.lt-md="0"
    >
      <ng-container *ngFor="let item of standardPages">
        <jazzmo-header-item [link]="item.page.meta.slug | slugRoute" (mouseover)="handleMouseOver(item, $event)">{{
          item.page.title
        }}</jazzmo-header-item>
        <ng-container *ngIf="item.page.meta.slug === currentSlug">
          <jazzmo-header-submenu [fxHide.gt-sm]="true" [items]="defaultSubmenuItems"></jazzmo-header-submenu>
        </ng-container>
      </ng-container>
      <jazzmo-header-item [href]="'https://muziekmozaiek.be/winkel/#jazz'">Shop</jazzmo-header-item>
    </div>

    <div [fxShow.lt-md]="menuOpen" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="2rem" fxLayout.lt-md="column">
      <jazzmo-header-item class="primary-color" *ngFor="let item of contentMenuPages" [link]="item.page.meta.slug | slugRoute">{{
        item.page.title
      }}</jazzmo-header-item>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem" fxLayout.lt-md="column" [fxShow.lt-md]="false">
      <div fxLayout="row" fxLayoutGap="0.5rem">
        <jazzmo-header-social-link link="https://www.facebook.com/jazzandmo" [icon]="icons.facebookSquare"></jazzmo-header-social-link>
        <jazzmo-header-social-link
          link="https://www.youtube.com/channel/UCQEMc0VmfUABGAQFR8A3bnA"
          [icon]="icons.youtube"
        ></jazzmo-header-social-link>
        <jazzmo-header-social-link link="https://www.instagram.com/jazzandmo/" [icon]="icons.instagram"></jazzmo-header-social-link>
      </div>
      <a [routerLink]="jazzmoCustomPath.search | slugRoute">
        <fa-icon class="primary-color" [icon]="icons.search" size="lg"></fa-icon>
      </a>
    </div>
  </div>
  <div>
    <jazzmo-header-submenu
      [fxShow.lt-md]="false"
      [items]="subMenuItems$ | async"
      [offset]="submenuOffset"
      class="hover-menu"
      [ngClass]="{ items: subMenuItems$ | async }"
    ></jazzmo-header-submenu>
    <jazzmo-header-submenu [fxShow.lt-md]="false" [items]="defaultSubmenuItems" [offset]="defaultSubmenuOffset"></jazzmo-header-submenu>
  </div>
</header>
