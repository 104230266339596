import { NgModule } from '@angular/core';
import { ContentComponent } from './content.component';
import { SharedModule } from '../../shared/shared.module';
import { LacertaMapperModule } from '@lacerta/util';
import { LacertaWagtailModule } from '@lacerta/wagtail';

@NgModule({
  declarations: [ContentComponent],
  imports: [SharedModule, LacertaMapperModule, LacertaWagtailModule],
})
export class ContentModule {}
