import { Pipe, PipeTransform } from '@angular/core';

const typeNameMap: Map<string, string> = new Map<string, string>();
typeNameMap.set('jazzmo.VideoDetailPage', 'video');
typeNameMap.set('jazzmo.PlayListPage', 'playlist');
typeNameMap.set('jazzmo.JazzplanPage', 'Jazzplan');
typeNameMap.set('jazzmo.PodcastPage', 'podcast');
typeNameMap.set('jazzmo.ConcertReviewPage', 'concertreview');
typeNameMap.set('jazzmo.AlbumReviewPage', 'albumreview');
typeNameMap.set('jazzmo.EventDetailPage', 'event');

@Pipe({ name: 'typeToName' })
export class TypeToNamePipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return value;
    }

    return typeNameMap.get(value);
  }
}
