import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JazzmoHomeComponent } from './home.component';
import { SharedModule } from '../../shared/shared.module';
import { GetjazzmoModule } from '../getjazzmo/getjazzmo.module';
import { LacertaImageModule } from '@lacerta/ui';

@NgModule({
  imports: [CommonModule, SharedModule, GetjazzmoModule, LacertaImageModule],
  declarations: [JazzmoHomeComponent],
})
export class HomeModule {}
