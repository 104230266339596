import { NgModule } from '@angular/core';
import { OverviewComponent } from './overview.component';
import { SharedModule } from '../../shared/shared.module';
import { OverviewItemsComponent } from './overview-items/overview-items.component';

@NgModule({
  declarations: [OverviewComponent, OverviewItemsComponent],
  imports: [SharedModule],
  exports: [OverviewItemsComponent],
})
export class OverviewModule {}
