<section>
  <ng-container *ngIf="currentDetailPage$ | async as home">
    <lacerta-hero [video]="home.background_video" [images]="home.background_images | streamFieldsToValues | asRenderedUiImages: 'large'">
      <div *ngIf="home.intro" class="intro">
        <lacerta-rich-text-block [content]="home.intro"></lacerta-rich-text-block>
        <jazzmo-arrow [tail]="true" rotate="90deg"></jazzmo-arrow>
      </div>
      <div class="spotlight" *ngIf="spotLight$ | async as spotLight">
        <a routerLink="/{{ spotLight.meta.slug }}">
          <div class="dot">
            <jazzmo-arrow [secondary]="true"></jazzmo-arrow>
          </div>
          <h2>{{ spotLight.title }}</h2>
          <h3 *ngIf="spotLight.subtitle">{{ spotLight.subtitle }}</h3>
        </a>
      </div>
    </lacerta-hero>
  </ng-container>
</section>
