import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WagtailStreamField } from '@lacerta/wagtail';
import { LacertaDocumentChooserBlock, LacertaImageChooserBlock } from '@lacerta/cms';

@Component({
  selector: 'jazzmo-header-logo',
  template: `
    <a *ngIf="logo" class="logo" [routerLink]="link">
      <img *ngIf="logo.type === 'image'" [src]="logoAsImage.renditions['small'].src" [alt]="alt" />
      <div *ngIf="logo.type === 'svg'" class="svg" aria-label="{alt}" [inlineSVG]="logoAsDocument.url"></div>
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderLogoComponent {
  @Input()
  logo?: WagtailStreamField<LacertaDocumentChooserBlock | LacertaImageChooserBlock> | null;
  @Input()
  link?: string;
  @Input()
  alt?: string;

  get logoAsImage() {
    return this.logo?.value as LacertaImageChooserBlock;
  }

  get logoAsDocument() {
    return this.logo?.value as LacertaDocumentChooserBlock;
  }
}
