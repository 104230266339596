<section class="input">
  <div fxLayout="row" class="container">
    <label for="search_input">
      <fa-icon fxFlex="0 0 auto" [icon]="searchIcon" size="lg"></fa-icon>
    </label>
    <input
      #search_input
      id="search_input"
      fxFlex
      type="text"
      name="fname"
      (keydown)="keydown()"
      (keyup)="keyup()"
      autofocus
      [value]="initialQuery"
    />
  </div>
</section>

<section class="items">
  <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="4em" fxLayoutAlign="flex-start">
    <ng-container *ngFor="let item of items">
      <a
        fxFlex="0 1 calc(16.6% - 4em)"
        fxFlex.lt-md="0 1 calc(33.3% - 4em)"
        fxFlex.lt-sm="0 1 calc(50% - 4em)"
        class="item"
        routerLink="/{{ item.meta.slug }}"
      >
        <div class="thumbnail" [ngStyle]="{ 'background-image': 'url(' + item.overview_thumbnail_square.url + ')' }">
          <div class="dot">
            <jazzmo-arrow [secondary]="true"></jazzmo-arrow>
          </div>
        </div>
        <div class="type">{{ item.meta.type | typeToName }}</div>
        <div class="text">
          <h1>{{ item.title }}</h1>
          <h2>{{ item.overview_subtitle }}</h2>
        </div>
      </a>
    </ng-container>
  </div>
</section>
