<section class="container" *ngIf="currentDetailPage$ | async as review">
  <article>
    <header fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="4em" fxLayoutAlign="flex-start">
      <div fxFlex="0 1 calc(33.3% - 4em)" fxFlex.lt-md="0 1 calc(50% - 4em)" fxFlex.lt-sm="100%"></div>
      <div fxFlex="0 1 calc(66.6% - 4em)" fxFlex.lt-md="0 1 calc(50% - 4em)" fxFlex.lt-sm="100%">
        <h1>{{ review.artist }}</h1>
        <h2>{{ review.title }}</h2>
      </div>
    </header>
    <main fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="4em" fxLayoutAlign="flex-start">
      <div fxFlex="0 1 calc(33.3% - 4em)" fxFlex.lt-md="0 1 calc(50% - 4em)" fxFlex.lt-sm="100%" class="cover">
        <figure [ngStyle]="{ 'background-image': 'url(' + review.cover?.url + ')' }"></figure>
      </div>
      <div fxFlex="0 1 calc(16% - 4em)" fxFlex.lt-md="0 1 calc(50% - 4em)" fxFlex.lt-sm="100%" class="meta">
        <div class="label">{{ review.label }}</div>
        <div class="year">{{ review.year }}</div>
        <ul class="tags">
          <li *ngIf="review.series"><jazzmo-tag [value]="review.series"></jazzmo-tag></li>
          <li *ngFor="let tag of review.tags"><jazzmo-tag [value]="tag"></jazzmo-tag></li>
          <!-- TODO: add search link -->
        </ul>
      </div>
      <div fxFlex="0 1 calc(50.6% - 4em)" fxFlex.lt-md="0 1 calc(50% - 4em)" fxFlex.lt-sm="100%" class="text">
        <lacerta-rich-text-block [content]="review.review"></lacerta-rich-text-block>
      </div>
    </main>
  </article>

  <hr />

  <ng-container *ngIf="moreItems$ | async as items">
    <section class="more" *ngIf="items.length > 0">
      <h2>Meer van {{ review.artist }}?</h2>
      <jazzmo-overview-items [items]="items"></jazzmo-overview-items>
    </section>
  </ng-container>
</section>
