<section *ngIf="currentDetailPage$ | async as content">
  <header *ngIf="content.subtitle">
    <h2>{{ content.subtitle }}</h2>
  </header>

  <div fxLayout="row" fxLayout.lt-sm="column" class="container" *ngFor="let item of content.body">
    <div fxFlex="1 1 25%" fxFlex.lt-sm="100%" class="title">
      <h1>{{ item.value.title }}</h1>
    </div>
    <div fxFlex="1 1 75%" fxFlex.lt-sm="100%" class="content">
      <lacerta-rich-text-block [content]="item.value.text"></lacerta-rich-text-block>
      <ul class="links">
        <li *ngFor="let link of item.value.links">
          <a *ngIf="link.value.url" [href]="link.value.url" target="_blank">
            <div *ngIf="link.type === 'button'" class="button dot">
              {{ (link.value | lacertaMapper: asContentButtonBlock).text }}
            </div>
            <div *ngIf="link.type === 'cover'" class="cover">
              <img [src]="((link.value | lacertaMapper: asContentCoverBlock).image | wagtailImageRendition: 'small').src" />
            </div>
          </a>
          <ng-container *ngIf="!link.value.url">
            <div *ngIf="link.type === 'button'" class="button dot">
              {{ (link.value | lacertaMapper: asContentButtonBlock).text }}
            </div>
            <div *ngIf="link.type === 'cover'" class="cover">
              <img [src]="((link.value | lacertaMapper: asContentCoverBlock).image | wagtailImageRendition: 'small').src" />
            </div>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</section>
