<section class="container" *ngIf="currentDetailPage$ | async as playlist">
  <header>
    <h2>Playlist {{ playlist.identifier }}</h2>
    &nbsp;
    <h1>{{ playlist.title }}</h1>
  </header>
  <hr />
  <div class="spotify">
    <h3 *ngIf="playlist.spotify_url">
      <a [href]="playlist.spotify_url" target="_blank">
        Beluister de volledige playlist op Spotify &nbsp;&nbsp; <fa-icon [icon]="iconPlay"></fa-icon
      ></a>
    </h3>
  </div>
  <ul class="playlist">
    <li *ngFor="let song of playlist.playlist; let i = index" fxLayout="row wrap" fxLayoutAlign="flex-start" fxLayout.lt-sm="column">
      <div fxFlex="0 1 calc(33.3% - 3em)" class="title">
        <div class="dot">{{ i + 1 }}</div>
        <h4>{{ song.value.artist }}</h4>
        {{ song.value.title }}
      </div>
      <div fxFlex="0 1 50%" class="description" [innerHtml]="song.value.description"></div>
      <div fxFlex="0 0 16.6%" class="link">
        <ng-container *ngIf="song.value.link?.url">
          <a [href]="song.value.link.url" target="_blank">
            <fa-icon [icon]="iconPlay"></fa-icon>
            <label>{{ song.value.link.text }}</label>
          </a>
        </ng-container>
      </div>
    </li>
  </ul>
</section>
