import { NgModule } from '@angular/core';
import { DetailComponent } from './detail.component';
import { SharedModule } from '../../shared/shared.module';
import { DetailGalleryComponent } from './detail-gallery/detail-gallery.component';

@NgModule({
  declarations: [DetailComponent, DetailGalleryComponent],
  imports: [SharedModule],
})
export class DetailModule {}
