import { Component } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { filter, switchMap } from 'rxjs/operators';
import { AlbumReviewPage } from '../../core/jazzmo-wagtail/page/page.model';
import { JazzmoOverviewApiService } from '../../core/jazzmo-wagtail/overview/overview-api.service';

@Component({
  selector: 'jazzmo-album-review',
  templateUrl: './album-review.component.html',
  styleUrls: ['./album-review.component.scss'],
})
export class AlbumReviewComponent extends LacertaDynamicPageWithMetaComponent<AlbumReviewPage> {
  constructor(lacertaCmsNgrxFacade: LacertaCmsNgrxFacade, private overviewService: JazzmoOverviewApiService) {
    super(lacertaCmsNgrxFacade);
  }
  moreItems$ = this.currentDetailPage$.pipe(
    filter((page) => !!page.artist),
    switchMap((page) => this.overviewService.getMoreItems(page.artist, page))
  );
}
