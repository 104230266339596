import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JazzmoOverviewApiService } from './overview/overview-api.service';
import { JazzmoMailchimpApiService } from './mailchimp/mailchimp-api.service';

@NgModule({
  imports: [CommonModule],
  providers: [JazzmoOverviewApiService, JazzmoMailchimpApiService],
})
export class JazzmoWagtailModule {}
