import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LacertaCoreModule } from '@lacerta/core';
import { LacertaWagtailComponentTypeMapping } from '@lacerta/cms';
import { metaConfig } from './meta.config';
import { RouterModule } from '@angular/router';
import { JazzmoHomeComponent } from '../feature/home/home.component';
import { OverviewComponent } from '../feature/overview/overview.component';
import { DetailComponent } from '../feature/detail/detail.component';
import { ContentComponent } from '../feature/content/content.component';
import { PlaylistComponent } from '../feature/playlist/playlist.component';
import { PodcastComponent } from '../feature/podcast/podcast.component';
import { AlbumReviewComponent } from '../feature/album-review/album-review.component';
import { SearchComponent } from '../feature/search/search.component';
import { SearchModule } from '../feature/search/search.module';
import { HomeModule } from '../feature/home/home.module';
import { OverviewModule } from '../feature/overview/overview.module';
import { DetailModule } from '../feature/detail/detail.module';
import { PlaylistModule } from '../feature/playlist/playlist.module';
import { AlbumReviewModule } from '../feature/album-review/album-review.module';
import { ContentModule } from '../feature/content/content.module';
import { PodcastModule } from '../feature/podcast/podcast.module';
import { JazzmoCustomPath } from './custom-path.model';
import { JazzmoWagtailModule } from './jazzmo-wagtail/jazzmo-wagtail.module';
import { ThemeService } from './theme.service';
import { InlineSVGModule } from 'ng-inline-svg';
import { environment } from '../../environments/environment';
import { JazzmoWagtailPageType } from './jazzmo-wagtail/page/page.model';
import { preventReimport } from '@lacerta/util';
import { JazzplanComponent } from '../feature/jazzplan/jazzplan.component';
import { JazzplanModule } from '../feature/jazzplan/jazzplan.module';

const dynamicFeatureModules = [
  HomeModule,
  OverviewModule,
  DetailModule,
  PlaylistModule,
  JazzplanModule,
  AlbumReviewModule,
  ContentModule,
  PodcastModule,
];
const wagtailComponentTypeMapping: LacertaWagtailComponentTypeMapping = [
  [JazzmoWagtailPageType.homePage, JazzmoHomeComponent],
  [JazzmoWagtailPageType.videoPage, OverviewComponent],
  [JazzmoWagtailPageType.videoDetailpage, DetailComponent],
  [JazzmoWagtailPageType.soundPage, OverviewComponent],
  [JazzmoWagtailPageType.extraPage, OverviewComponent],
  [JazzmoWagtailPageType.playlistPage, PlaylistComponent],
  [JazzmoWagtailPageType.jazzplanPage, JazzplanComponent],
  [JazzmoWagtailPageType.podcastPage, PodcastComponent],
  [JazzmoWagtailPageType.reviewPage, OverviewComponent],
  [JazzmoWagtailPageType.concertReviewPage, DetailComponent],
  [JazzmoWagtailPageType.albumReviewPage, AlbumReviewComponent],
  [JazzmoWagtailPageType.eventPage, OverviewComponent],
  [JazzmoWagtailPageType.eventDetailPage, DetailComponent],
  [JazzmoWagtailPageType.contentPage, ContentComponent],
];

@NgModule({
  imports: [
    CommonModule,
    dynamicFeatureModules,
    JazzmoWagtailModule,
    LacertaCoreModule.forRoot({
      wagtailComponentTypeMapping,
      metaConfig,
      customRoutes: [{ path: JazzmoCustomPath.search, component: SearchComponent }],
      rootPageType: JazzmoWagtailPageType.root,
      menuParentPageType: JazzmoWagtailPageType.root,
      environment,
    }),
    SearchModule,
    InlineSVGModule.forRoot(),
  ],
  providers: [ThemeService],
  exports: [RouterModule],
})
export class JazzmoCoreModule {
  constructor(@Optional() @SkipSelf() parentModule: JazzmoCoreModule) {
    preventReimport(parentModule, 'JazzmoCoreModule');
  }
}
