import { Component, OnInit } from '@angular/core';
import { MixcloudService } from './mixcloud.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@UntilDestroy()
@Component({
  selector: 'jazzmo-mixcloud',
  templateUrl: './mixcloud.component.html',
  styleUrls: ['./mixcloud.component.scss'],
})
export class MixcloudComponent implements OnInit {
  url?: SafeResourceUrl | null;
  iconClose = faTimes;

  constructor(private mixcloudService: MixcloudService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.mixcloudService.events$.pipe(untilDestroyed(this)).subscribe((url) => {
      const light = url.indexOf('light=1') < 0;
      const autoplay = url.indexOf('autoplay=1') < 0;
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${url}${light ? '&light=1' : ''}${autoplay ? '&autoplay=1' : ''}`);
      document.body.classList.add('mixcloud');
    });
  }

  close() {
    this.url = null;
    document.body.classList.remove('mixcloud');
  }
}
