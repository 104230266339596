import { Inject, Injectable } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { JazzmoRootPage } from './jazzmo-wagtail/root/root.model';
import { DOCUMENT } from '@angular/common';
import { LacertaCmsNgrxFacade } from '@lacerta/cms';
import { cast } from '@lacerta/util';

export enum JazzmoTheme {
  primary = '--primary',
  secondary = '--secondary',
}

@Injectable()
export class ThemeService {
  constructor(private wagtailNgrxFacade: LacertaCmsNgrxFacade, @Inject(DOCUMENT) private document: Document) {}

  loadTheme() {
    this.wagtailNgrxFacade.rootPage$
      .pipe(
        take(1),
        cast<JazzmoRootPage>(),
        tap((rootPage) => this.document.body.style.setProperty(JazzmoTheme.primary, rootPage.colour_a)),
        tap((rootPage) => this.document.body.style.setProperty(JazzmoTheme.secondary, rootPage.colour_b))
      )
      .subscribe();
  }
}
