import { NgModule } from '@angular/core';
import { PodcastComponent } from './podcast.component';
import { SharedModule } from '../../shared/shared.module';
import { OverviewModule } from '../overview/overview.module';

@NgModule({
  declarations: [PodcastComponent],
  imports: [SharedModule, OverviewModule],
})
export class PodcastModule {}
