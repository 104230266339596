/* eslint-disable @typescript-eslint/naming-convention */
import { WagtailImageRenditionField } from '@lacerta/wagtail';
import { LacertaWagtailPage } from '@lacerta/cms';

export interface OverviewPage extends LacertaWagtailPage {
  intro: string;
}

export interface OverviewItem extends LacertaWagtailPage {
  overview_thumbnail: WagtailImageRenditionField;
  overview_thumbnail_square: WagtailImageRenditionField;
  overview_thumbnail_16_9: WagtailImageRenditionField;
  overview_subtitle: string;
  overview_series: string;
  tags: string[];
}

export enum OverviewFields {
  overview_series = 'overview_series',
  overview_thumbnail = 'overview_thumbnail',
  overview_thumbnail_square = 'overview_thumbnail_square',
  overview_thumbnail_16_9 = 'overview_thumbnail_16_9',
  overview_subtitle = 'overview_subtitle',
  tags = 'tags',
}
