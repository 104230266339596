import { Component } from '@angular/core';
import { LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { LacertaMetaField, mapImageToMetaValues } from '@lacerta/meta';
import { JazzplanPage } from '../../core/jazzmo-wagtail/page/page.model';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'jazzmo-jazzplan',
  templateUrl: './jazzplan.component.html',
  styleUrls: ['./jazzplan.component.scss'],
})
export class JazzplanComponent extends LacertaDynamicPageWithMetaComponent<JazzplanPage> {
  iconPlay = faMapMarker;

  mapCurrentDetailPageToMetaValues = (page: JazzplanPage) => ({
    ...(!this.context?.meta.search_image && page.cover
      ? mapImageToMetaValues(page.cover.url, page.cover.width, page.cover.height)
      : undefined),
    ...(!this.context?.meta.seo_title && { [LacertaMetaField.title]: page.title }),
  });
}
