<section class="container" *ngIf="currentDetailPage$ | async as podcast">
  <article>
    <main fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="4em" fxLayoutAlign="flex-start">
      <div fxFlex="0 1 calc(66.6% - 4em)" fxFlex.lt-md="0 1 calc(50% - 4em)" fxFlex.lt-sm="100%" class="cover">
        <figure [ngStyle]="{ 'background-image': 'url(' + podcast.cover.url + ')' }">
          <div class="dot" (click)="play()">
            <jazzmo-arrow [secondary]="true"></jazzmo-arrow>
          </div>
        </figure>
      </div>
      <div fxFlex="0 1 calc(33.3% - 4em)" fxFlex.lt-md="0 1 calc(50% - 4em)" fxFlex.lt-sm="100%" class="text">
        <header>
          <h1>{{ podcast.title }}</h1>
        </header>
        <hr />
        <ul class="tags">
          <li *ngIf="podcast.series"><jazzmo-tag [value]="podcast.series"></jazzmo-tag></li>
          <li *ngFor="let tag of podcast.tags"><jazzmo-tag [value]="tag"></jazzmo-tag></li>
        </ul>
        <lacerta-rich-text-block [content]="podcast.body"></lacerta-rich-text-block>
      </div>
    </main>
  </article>

  <hr />

  <ng-container *ngIf="moreItems$ | async as items">
    <section class="more" *ngIf="items.length > 0">
      <h2>Meer van {{ podcast.artist }}?</h2>
      <jazzmo-overview-items [items]="items"></jazzmo-overview-items>
    </section>
  </ng-container>
</section>
