import { Component } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { ContentButtonBlock, ContentCoverBlock, ContentPage } from '../../core/jazzmo-wagtail/page/page.model';
import { LacertaMapper } from '@lacerta/util';

@Component({
  selector: 'jazzmo-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent extends LacertaDynamicPageWithMetaComponent<ContentPage> {
  constructor(lacertaCmsNgrxFacade: LacertaCmsNgrxFacade) {
    super(lacertaCmsNgrxFacade);
  }
  asContentCoverBlock: LacertaMapper<ContentCoverBlock | ContentButtonBlock, ContentCoverBlock> = (value) =>
    (value as unknown) as ContentCoverBlock;
  asContentButtonBlock: LacertaMapper<ContentCoverBlock | ContentButtonBlock, ContentButtonBlock> = (value) =>
    (value as unknown) as ContentButtonBlock;
}
