import { Component, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { OnChange } from '@lacerta/util';

@Component({
  selector: 'jazzmo-arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss'],
})
export class ArrowComponent {
  @Input() rotate: number | string = 0;
  @Input() spinning = false;

  @Input()
  @OnChange('callCoerceBooleanProperty')
  tail = false;

  @Input()
  @OnChange('callCoerceBooleanProperty')
  secondary = false;

  callCoerceBooleanProperty(value: unknown) {
    coerceBooleanProperty(value);
  }
}
