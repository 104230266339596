import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { JazzmoRootPage, PartnerBlock } from '../../../core/jazzmo-wagtail/root/root.model';
import { WagtailStreamField } from '@lacerta/wagtail';
import { Observable } from 'rxjs';
import { LacertaCmsNgrxFacade, LacertaDocumentChooserBlock, LacertaImageChooserBlock } from '@lacerta/cms';
import { cast, LacertaMapper } from '@lacerta/util';

@Component({
  selector: 'jazzmo-footer-bottom',
  templateUrl: './footer-bottom.component.html',
  styleUrls: ['./footer-bottom.component.scss'],
})
export class FooterBottomComponent {
  partners$: Observable<WagtailStreamField<PartnerBlock>[]> = this.lacertaNgrxFacade.rootPage$.pipe(
    cast<JazzmoRootPage>(),
    map((rootPage) => rootPage.partners)
  );
  asImageBlock: LacertaMapper<LacertaDocumentChooserBlock | LacertaImageChooserBlock, LacertaImageChooserBlock> = (value) =>
    value as LacertaImageChooserBlock;
  asDocumentBlock: LacertaMapper<LacertaDocumentChooserBlock | LacertaImageChooserBlock, LacertaDocumentChooserBlock> = (value) =>
    value as LacertaDocumentChooserBlock;

  constructor(private lacertaNgrxFacade: LacertaCmsNgrxFacade) {}
}
