import { Component } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { PodCastPage } from '../../core/jazzmo-wagtail/page/page.model';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { MixcloudService } from '../mixcloud/mixcloud.service';
import { JazzmoOverviewApiService } from '../../core/jazzmo-wagtail/overview/overview-api.service';

@Component({
  selector: 'jazzmo-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.scss'],
})
export class PodcastComponent extends LacertaDynamicPageWithMetaComponent<PodCastPage> {
  constructor(
    lacertaCmsNgrxFacade: LacertaCmsNgrxFacade,
    private overviewService: JazzmoOverviewApiService,
    private mixcloudService: MixcloudService
  ) {
    super(lacertaCmsNgrxFacade);
  }
  moreItems$ = this.currentDetailPage$.pipe(
    filter((page) => !!page.artist),
    switchMap((page) => this.overviewService.getMoreItems(page.artist, page))
  );

  play = () =>
    this.currentDetailPage$
      .pipe(
        take(1),
        tap((page) => (this.play = () => this.mixcloudService.play(page.mixcloud_url)))
      )
      .subscribe();
}
