import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LacertaCmsNgrxFacade, LacertaDynamicPageWithMetaComponent } from '@lacerta/cms';
import { DetailPage, DetailPresentation } from '../../core/jazzmo-wagtail/detail/detail.model';
import { faInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'jazzmo-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent extends LacertaDynamicPageWithMetaComponent<DetailPage> implements OnInit {
  constructor(
    lacertaCmsNgrxFacade: LacertaCmsNgrxFacade,
    private changeDetectionRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
    super(lacertaCmsNgrxFacade);
  }
  playing = false;
  closed = false;
  mobile = false;
  bijschrift?: string;

  iconClose = faTimes;
  iconInfo = faInfo;

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait]).subscribe((result) => {
      this.mobile = result.matches;
    });
  }

  getVideo = (presentations: DetailPresentation[]): DetailPresentation | undefined => presentations.find((value) => value.type === 'video');

  setBijSchrift(presentation: DetailPresentation): void {
    this.bijschrift = presentation.value.bijschrift;
    this.changeDetectionRef.detectChanges();
  }

  play(): void {
    if (!this.mobile) {
      this.closed = true;
    }
    this.playing = true;
  }
}
